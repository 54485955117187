import { render, staticRenderFns } from "./NoRolesFound.vue?vue&type=template&id=c34a344c&"
import script from "./NoRolesFound.vue?vue&type=script&lang=ts&"
export * from "./NoRolesFound.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports